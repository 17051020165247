import React from 'react'
import tw from 'twin.macro'


export default function ServicesDetail({data,page}){
  let finalData= data?.schema_json;  
  let content= {
      heading:"All-in-one platform",
      sub_heading:"",
      description:"",
      cards:[
          {
              heading:"Invite team members",
              description:"Tempor tellus in aliquet eu et sit nulla tellus. Suspendisse est, molestie blandit quis ac. Lacus."
          },
          {
              heading:"Notifications",
              description:"Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient.",
          },
          {
              heading:"List view",
              description:"Etiam cras augue ornare pretium sit malesuada morbi orci, venenatis. Dictum lacus.",
          }
      ],
     ...finalData
}

let cardsData = content.cards.map((card, i) => (
  <div key={i} tw="relative p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 hover:shadow-xl bg-gradient-to-r from-primary-100 to-secondary-200 hover:cursor-pointer">
            <div tw="relative p-5 rounded-sm">
              <div css={[tw`flex flex-col mb-2 lg:items-center lg:flex-row`, !card.heading && tw`lg:flex-col`]} >
                <div tw="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full bg-secondary-100 lg:mb-0">
                  <svg
                    tw="w-8 h-8 text-primary-900"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                {card.heading && <h6 tw="font-semibold leading-5">{card.heading}</h6>}
              </div>
              <p tw="mb-2 text-sm text-gray-900">
              {card.description}
              </p>
            </div>
        </div>
  ))
    return (
      <div tw="px-4 py-4 md:py-8 mx-auto sm:max-w-5xl md:px-24 lg:px-8 mb-12 ">
          <div tw="text-center">
            <p tw="text-base text-gray-700 md:text-lg">
            What's the latest
            </p>
            <h5 tw="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-primary-900">
            Pagespeed Trends for 2021
            </h5>
          </div>
        <div tw="grid gap-6 row-gap-5 lg:grid-cols-3 ">
        {cardsData}
          </div>
      </div>
    );
  };